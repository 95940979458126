@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  @apply bg-gray-200  
}

.parent-login{
  @apply w-11/12 md:w-6/12 lg:w-[28%] bg-white m-auto p-3 rounded-sm   mt-32 shadow-md shadow-gray-400
}

.head-login{
  @apply  text-xl font-bold mb-4
}

.lbl{
  @apply font-semibold 
}

.input{
  @apply   outline-none border focus:border-blue-400 p-2
}

.parent-input{
  @apply flex flex-col gap-1
}

.btn-primary{
  @apply bg-blue-500 hover:bg-blue-600 text-white font-bold py-1.5 px-3 rounded duration-500
}

.btn-secondary {
  @apply bg-gray-500 hover:bg-gray-600 text-white font-bold py-1.5 px-3 rounded duration-500
}

.btn-danger{
  @apply bg-red-500 hover:bg-red-600 text-white font-bold py-1.5 px-3 rounded duration-500
}

.btn-warning{
  @apply bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1.5 px-3 rounded duration-500
}

.btn-info{
  @apply bg-teal-500 hover:bg-teal-600 text-white font-bold py-1.5 px-3 rounded duration-500
}




/* Modal */
.modal {
  
  @apply fixed z-10 inset-0 overflow-y-auto duration-500;
}

.modal-dialog {
  @apply flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 duration-700;
}

.modal-overlay {
  @apply fixed inset-0 bg-gray-500 opacity-75;
}

.modal-content {
  @apply inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full;
}

.modal-header {
  @apply bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4;
}

.modal-header h3 {
  @apply text-lg leading-6 font-medium text-gray-900;
}

.modal-body {
  @apply px-4 py-2;
}

.modal-footer {
  @apply bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse;
}

.head{
  @apply bg-teal-500  p-2 rounded-sm text-white
}
.parent{
  @apply w-[95%] bg-white m-auto mt-2
}
.container{
  @apply grid grid-cols-1 md:grid-cols-2 px-3 py-2 gap-3
}

.btn-close {
  @apply  bg-white text-gray-700 border border-gray-300 px-4 py-2 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

.btn-success {
  @apply  bg-green-500 text-gray-100 border border-gray-300 px-4 py-2 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}


.parent-table{
  @apply overflow-x-auto mt-2
}

table{
  @apply table-auto min-w-full divide-y divide-gray-200
}

thead{
  @apply bg-gray-100
 }

 label{
  @apply capitalize
 }

 th{
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
  
 }
 tbody{
  @apply bg-white divide-y divide-gray-200
 }

 td{
  @apply px-6 py-4 whitespace-nowrap
  
 }

.inputFile{
  @apply flex mt-2 items-center p-1 gap-3 w-11/12 m-auto text-center justify-center rounded-sm border border-gray-300 border-dashed bg-gray-50 cursor-pointer
}
.file-container{
  @apply flex gap-3 justify-center items-center
}

.bottom-space{
  @apply pb-10
}

.coustm-th{
  @apply text-gray-100 border-2 border-gray-200  p-0
  
}
.coustm-thead{
  @apply bg-slate-900 border text-gray-100 p-0
}
.coustm-td{
  @apply border-2 px-1 py-2 border-gray-100
}
.paginate-container{
  @apply flex  items-center mt-2  gap-4 justify-center
}
.arrow{
  @apply bg-gray-200 p-0 text-xl px-1 py-0.5 rounded-sm text-center mx-1
}
.active{
  @apply  bg-indigo-500 hover:bg-indigo-600 text-gray-100 px-2   rounded-sm cursor-pointer
}



